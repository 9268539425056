html {
    height: 100vh;
    width: 100vw;
}

body {
    background-color: #fff;
    color: #333;
}

/* Estilos para modo oscuro */
body.dark {
    background-color: #222;
    color: #eee;
}

