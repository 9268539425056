@import url('https://fonts.googleapis.com/css2?family=Playwrite+IT+Moderna:wght@100..400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Economica:ital,wght@0,400;0,700;1,400;1,700&family=Rajdhani:wght@300;400;500;600;700&display=swap');

.banner {
    position: relative;
    width: 100%;
    margin-top: 60px;
    overflow: hidden;
}
  
.banner-img {
    width: 100%;
    display: block;
    object-fit: cover;
}
  
.banner-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4); 
    pointer-events: none;
    z-index: 1;
}

.banner-text-container {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
    z-index: 2;
}
  
.banner-title {
    font-family: "Economica", serif;
    font-weight: 500;
    font-style: normal;
    font-size: 2.5rem;
    margin-bottom: 0.5rem;
    letter-spacing: 3px;
    color: #f0f0f0;
}
  
.banner-slogan {
    font-family: "Rajdhani", serif;
    font-style: normal;
    font-size: 1.2rem;
    font-weight: 400;
    text-shadow: 0 1px px rgba(0, 0, 0, 0.5);
    color: #f0f0f0;
}