@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Economica:ital,wght@0,400;0,700;1,400;1,700&family=Rajdhani:wght@300;400;500;600;700&display=swap');

/* Navbar principal */
.navbar {
position: fixed;
top: 0;
left: 0;
width: 100%;
height: 60px; /* Altura del navbar */
background: #fff;
box-shadow: 0 2px 4px rgba(0,0,0,0.1);
z-index: 1000;
}

/* Contenedor interno */
.navbar-container {
    padding: 0 1rem;
}

/* Alineación de columnas con Bootstrap */
.row {
    margin: 0;
}

/* Botón hamburguesa: redondo con fondo gris clarito */
/*.boton-hamburguer {
    background-color: #f0f0f0b0;
    border: none;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}*/

/* Estilos para el logo */
.logo {
    /* font-family: "Cormorant Garamond", serif;
    font-size: 1.6rem;
    font-weight: bold; */

    font-family: "Economica", serif;
    font-size: 1.6rem;
    font-weight: bold;
    letter-spacing: 3px;
}

/* Iconos del navbar */
.icon-navbar {
    font-size: 1.5rem;
    color: #333;
}

/* Drawer: menú lateral que aparece debajo del navbar */
.drawer {
    position: fixed;
    top: 60px;
    left: 0;
    width: 250px;
    height: calc(100vh - 60px);
    background-color: #f0f0f0;
    padding: 1rem;
    border-radius: 0 16px 16px 0;
    z-index: 900;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

/* Lista de items del menú */
.navList {
    list-style: none;
    padding: 0;
    margin: 0;
}
.nav-item {
    margin-bottom: 1rem;
    font-size: 20px;
    color: #333;
}
.nav-item a {
    text-decoration: none;
    color: inherit;
}

/* Overlay para cerrar el menú */
.overlay {
    position: fixed;
    top: 60px;
    left: 0;
    width: 100%;
    height: calc(100vh - 60px);
    background: rgba(0, 0, 0, 0.5);
    z-index: 800;
}

.drawer-footer {
    display: flex;
    justify-content: center; /* Centra horizontalmente */
    align-items: center;
}

/* Estilos en modo oscuro para el navbar */
body.dark .navbar {
    background: #222;
    color: #f0f0f0;
    box-shadow: 0 2px 4px rgba(0,0,0,0.5);
  }
  
body.dark .navbar .logo {
    color: #f0f0f0;
}

body.dark .navbar .icon-navbar {
    color: #f0f0f0;
}

/*body.dark .navbar .boton-hamburguer {
    background-color: #f0f0f06c;
}*/

/* Estilos para el drawer en modo oscuro */
body.dark .drawer {
    background: #333;
}

body.dark .drawer li a {
    color: #f0f0f0;
}