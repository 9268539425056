@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Economica:ital,wght@0,400;0,700;1,400;1,700&family=Rajdhani:wght@300;400;500;600;700&display=swap');

.secciones-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 1.5rem;
    width: 100vw;
    max-width: 430px; /* Tamaño típico de móvil */
    margin: 0 auto;
}

.seccion-row-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.seccion-card {
    width: 48%;
    height: 160px;
    border-radius: 30px;
    background: linear-gradient(145deg, #f0f0f0, #e0e0e0);
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.1), -5px -5px 15px rgba(255, 255, 255, 0.7);
    text-align: center;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.seccion-link {
    text-decoration: none;
    color: #333;
}

.seccion-icono {
    font-size: 2.3rem;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
    color: #333;
}

.seccion-card h3 {
    margin: 0.3rem 0;
    font-size: 1.3rem;
    font-weight: 600;
}

.seccion-card p {
    font-size: 0.9rem;
    font-weight: 300;
    color: #666;
}

.texto-seccion {
    width: 48%;
    font-size: 1rem;
    color: #444;
    font-weight: 400;
    padding: 0 0.5rem;
}

.boton-izquierda .texto-seccion {
    order: 2;
    text-align: left;
}

.boton-izquierda .seccion-card {
    order: 1;
}

.boton-derecha .texto-seccion {
    order: 1;
    text-align: right;
}

.boton-derecha .seccion-card {
    order: 2;
}
