:root {
    /* --color-primary: rgb(101, 196, 63);*/
    --color-primary: #28a745;
}

body {
    background-color: #f4f4f4;
    font-family: 'Arial', sans-serif;
}

.container-fluid {
    padding: 20px 15px 20px 15px;
}

.content {
    padding-top: 0px;
}

.main {
    height: 100vh;
}

.home {
    overflow-y: hidden;
}

h1 {
    color: #333;
    font-size: 2.5rem;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    text-transform: uppercase;
    padding-top: 2%;
    margin-bottom: 0 !important;
}

.botonPrimario {
    background-color: var(--color-primary);
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.botonPrimarioGaleria {
    background-color: #7c2aff;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.botonPrimarioRanking {
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.botonPrimarioViajes {
    background-color: #ff7f50;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.botonPrimarioUtilidades {
    background-color: #CD32CD;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.botonPrimarioOtros {
    background-color: #c7c700;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.botonPrimarioRecetas {
    background-color: #d13a3a;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.botonResetUtilidades {
    background-color: #969696;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.div-texto-home {
    position: fixed;
    text-align: center;
    width: 100%;
    padding-right: 24px;
}

.buttons-div {
    height: 100vh;
}

.idea-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #eee;
}

.idea-item:last-child {
    border-bottom: none;
}

.ideas-completadas {
    padding-bottom: 30px;
}
  
label {
    display: block;
    margin-bottom: 5px;
}
  
input, button, select {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
}

.input-group {
    margin-bottom: 15px;
}

input[type="text"],
select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: border 0.3s;
}

input[type="text"]:focus,
select:focus {
    border-color: #007bff;
    outline: none;
}

.list {
    background: white;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin: 20px 0px;
    padding: 15px;
}
  
.list h3 {
    margin-bottom: 10px;
}
  
.list ul {
    list-style-type: none;
    padding: 0;
}
  
.list li {
    background: #f4f4f4;
    margin-bottom: 5px;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.buttons {
    display: flex;
    gap: 10px;
}

.buttons-modal {
    gap: 2%;
}

.complete-button, .delete-button {
    background-color: var(--color-primary);
    color: white;
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
}

.delete-button {
    background-color: red;
}

.complete-button:hover {
    background-color: darkgreen;
}

.botonPrimario:hover {
    background-color: darkgreen;
}

.botonPrimarioGaleria:hover {
    background-color: #4100a8;
}

.botonPrimarioRanking:hover {
    background-color: #0056b3;
}

.botonPrimarioViajes:hover {
    background-color: #d6582a;
}

.botonPrimarioUtilidades:hover {
    background-color: #a02da0;
}

.botonPrimarioOtros:hover {
    background-color: #a3a300;
}

.botonPrimarioRecetas:hover {
    background-color: #b93333;
}

.botonResetUtilidades:hover {
    background-color: #727272;
}

.delete-button:hover {
    background-color: darkred;
}

.list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}

.list-header h3 {
    margin: 0;
}

.edit-button {
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 5px;
}

.text-center a {
    color: #007bff;
    text-decoration: none;
}

.text-center a:hover {
    text-decoration: underline;
}

/* INICIO MODAL */
.modal-body {
    padding: 15px;
}

.modal-body input {
    margin-bottom: 15px;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1050;
}

.custom-modal {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 80%;
    max-width: 500px;
    z-index: 1060;
    position: relative;
}

.custom-modal h2 {
    text-align: center;
    margin-bottom: 20px;
}

.custom-modal input {
    width: 100%;
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}
/* FIN MODAL */

.spinner-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.4);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2000;
}

.custom-spinner {
    width: 3rem;
    height: 3rem;
    border: 0.25em solid transparent;
    border-top-color: var(--color-primary);
}


/* INICIO GALERIA */
.galeria {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 10px;
    padding: 10px;
}

.foto-item {
    position: relative;
    width: 100%;
    aspect-ratio: 1; /* Mantiene la relación de aspecto cuadrada */
    overflow: hidden;
    border-radius: 8px;
    perspective: 1000px; /* Necesario para el efecto 3D */
}

.foto-container {
    position: absolute;
    width: 100%;
    height: 100%;
    transform-style: preserve-3d; /* Habilita el efecto 3D */
    transition: transform 0.6s; /* Duración del efecto de voltear */
    cursor: pointer; /* Cambia el cursor para indicar que es interactivo */
}

.foto-container.flipped {
    transform: rotateY(180deg); /* Rote el contenedor al pasar el ratón o hacer clic */
}

.foto {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden; /* Oculta la parte trasera cuando la imagen está visible */
    border-radius: 8px; /* Mantiene el borde redondeado */
    width: 100%;
    height: 100%;
    object-fit: cover; /* Asegura que la imagen cubra el contenedor */
    backface-visibility: hidden; /* Oculta la parte trasera cuando se voltea */
    transition: transform 0.2s;
}

.foto:hover {
    transform: scale(1.05); /* Efecto de zoom al pasar el mouse */
}

.foto-back {
    position: absolute;
    width: 100%;
    height: 100%;
    background: #f0f0f0; /* Color de fondo de la parte trasera */
    transform: rotateY(180deg); /* Gira la parte trasera para estar oculta inicialmente */
    backface-visibility: hidden; /* Oculta la parte trasera cuando la imagen está visible */
    display: flex; /* Centra el contenido en la parte trasera */
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem; /* Tamaño del texto en la parte trasera */
    color: #333; /* Color del texto en la parte trasera */
    text-align: center;
    font-size: 15px;
    font-family: 'Arial', sans-serif;

}

/* GALERIA BIEN */
/* .foto-item {
    width: 100%;
    aspect-ratio: 1;
    overflow: hidden;
    border-radius: 8px;
}

.foto {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.2s;
}

.foto:hover {
    transform: scale(1.05);
} */

/* FIN GALERIA */

/* INICIO BOTONES PRINCIPALES */
.button-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Centra horizontalmente */
    gap: 10px; /* Espacio entre los botones para pantallas grandes */
    margin: 0 auto;
    box-sizing: border-box; /* Asegura que el padding se incluya en el cálculo del ancho */
}

/* Botones */
.boton-ideas,
.boton-galeria,
.boton-ranking,
.boton-viajes,
.boton-utilidades,
.boton-otros,
.boton-recetas {
    padding: 15px 30px;
    font-size: 16px;
    color: #fff;
    border: none;
    border-radius: 50px; /* Bordes redondeados */
    box-shadow: 0 4px 15px rgba(0, 123, 255, 0.4);
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
    text-align: center; /* Alinea el texto al centro */
}

/* Botones */
@media (max-width: 400px) {
    .boton-ideas,
    .boton-galeria,
    .boton-ranking,
    .boton-viajes,
    .boton-utilidades,
    .boton-otros,
    .boton-recetas {
        min-width: 156px; /* Ancho mínimo aumentado para evitar botones demasiado pequeños */
        max-width: 250px; /* Ancho máximo para evitar que se estiren demasiado */
    }
}

@media (min-width: 401px) {
    .boton-ideas,
    .boton-galeria,
    .boton-ranking,
    .boton-viajes,
    .boton-utilidades,
    .boton-otros,
    .boton-recetas {
        min-width: 180px; /* Ancho mínimo aumentado para evitar botones demasiado pequeños */
        max-width: 250px; /* Ancho máximo para evitar que se estiren demasiado */
    }
}

/* Estilo específico para cada botón */
/* BOTÓN IDEAS */
.boton-ideas {
    background-color: #28a745; /* Color de fondo */
}

.boton-ideas:hover {
    background-color: #218838; /* Color al pasar el mouse */
    transform: translateY(-2px);
}

.boton-ideas:after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 300%;
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
    transform: translateX(-50%) scaleX(0);
    transition: transform 0.5s ease;
    z-index: 0;
}

.boton-ideas:hover:after {
    transform: translateX(-50%) scaleX(1);
}

/* BOTÓN GALERIA */
.boton-galeria {
    background-color: #7c2aff; /* Color de fondo */
}

.boton-galeria:hover {
    background-color: #4100a8; /* Color al pasar el mouse */
    transform: translateY(-2px);
}

.boton-galeria:after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 300%;
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
    transform: translateX(-50%) scaleX(0);
    transition: transform 0.5s ease;
    z-index: 0;
}

.boton-galeria:hover:after {
    transform: translateX(-50%) scaleX(1);
}

/* BOTÓN RANKING */
.boton-ranking {
    background-color: #007bff; /* Color de fondo */
}

.boton-ranking:hover {
    background-color: #0056b3; /* Color al pasar el mouse */
    transform: translateY(-2px);
}

.boton-ranking:after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 300%;
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
    transform: translateX(-50%) scaleX(0);
    transition: transform 0.5s ease;
    z-index: 0;
}

.boton-ranking:hover:after {
    transform: translateX(-50%) scaleX(1);
}

/* BOTÓN VIAJES */
.boton-viajes {
    background-color: #ff7f50; /* Color de fondo */
}

.boton-viajes:hover {
    background-color: #d6582a; /* Color al pasar el mouse */
    transform: translateY(-2px);
}

.boton-viajes:after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 300%;
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
    transform: translateX(-50%) scaleX(0);
    transition: transform 0.5s ease;
    z-index: 0;
}

.boton-viajes:hover:after {
    transform: translateX(-50%) scaleX(1);
}

/* BOTÓN UTILIDADES */
.boton-utilidades {
    background-color: #CD32CD; /* Color de fondo */
}

.boton-utilidades:hover {
    background-color: #a02da0; /* Color al pasar el mouse */
    transform: translateY(-2px);
}

.boton-utilidades:after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 300%;
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
    transform: translateX(-50%) scaleX(0);
    transition: transform 0.5s ease;
    z-index: 0;
}

.boton-utilidades:hover:after {
    transform: translateX(-50%) scaleX(1);
}

/* BOTÓN OTROS */
.boton-otros {
    background-color: #c7c700; /* Color de fondo */
}

.boton-otros:hover {
    background-color: #a3a300; /* Color al pasar el mouse */
    transform: translateY(-2px);
}

.boton-otros:after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 300%;
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
    transform: translateX(-50%) scaleX(0);
    transition: transform 0.5s ease;
    z-index: 0;
}

.boton-otros:hover:after {
    transform: translateX(-50%) scaleX(1);
}

/* BOTÓN RECETAS */
.boton-recetas {
    background-color: #d13a3a; /* Color de fondo */
}

.boton-recetas:hover {
    background-color: #b93333; /* Color al pasar el mouse */
    transform: translateY(-2px);
}

.boton-recetas:after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 300%;
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
    transform: translateX(-50%) scaleX(0);
    transition: transform 0.5s ease;
    z-index: 0;
}

.boton-recetas:hover:after {
    transform: translateX(-50%) scaleX(1);
}

/* Estilos responsivos para móvil */
@media (max-width: 767.98px) {
    .button-container .boton-ideas,
    .button-container .boton-galeria,
    .button-container .boton-ranking,
    .button-container .boton-viajes,
    .button-container .boton-utilidades,
    .button-container .boton-otros,
    .button-container .boton-recetas {
        flex: 1 1 calc(50% - 20px); /* Ocupa el 50% del ancho del contenedor menos el espacio del gap */
    }
}

/* BOTONES VERTICALES */
/* .button-container {
    display: flex;
    flex-direction: column;
    align-items: center; 
    gap: 20px;
}

.boton-ideas {
    padding: 15px 30px;
    font-size: 16px;
    color: #fff;
    background-color: #007bff; 
    border: none;
    border-radius: 50px;
    box-shadow: 0 4px 15px rgba(0, 123, 255, 0.4);
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
}

.boton-ideas:hover {
    background-color: #0056b3; 
    transform: translateY(-2px);
}

.boton-ideas:after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 300%;
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
    transform: translateX(-50%) scaleX(0);
    transition: transform 0.5s ease;
    z-index: 0;
}

.boton-ideas:hover:after {
    transform: translateX(-50%) scaleX(1);
}

.boton-galeria {
    padding: 15px 30px;
    font-size: 16px;
    color: #fff;
    background-color: #28a745; 
    border: none;
    border-radius: 50px;
    box-shadow: 0 4px 15px rgba(40, 167, 69, 0.4);
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    overflow: hidden;
}

.boton-galeria:hover {
    background-color: #218838;
    transform: translateY(-2px);
}

.boton-galeria:after {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    width: 300%;
    height: 100%;
    background: rgba(255, 255, 255, 0.2);
    transform: translateX(-50%) scaleX(0);
    transition: transform 0.5s ease;
    z-index: 0;
}

.boton-galeria:hover:after {
    transform: translateX(-50%) scaleX(1);
} */
/* FIN BOTONES PRINCIPALES */

/* Ranking.css */
.ranking-container {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .input-container {
    margin-bottom: 20px;
  }
  
  input[type="text"], input[type="number"] {
    padding: 10px;
    margin-right: 10px;
  }
  
  button {
    padding: 10px 15px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #0056b3;
  }
  
  .ranking-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .ranking-item {
    background: #f4f4f4;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: grab;
  }
  
  .ranking-item:active {
    cursor: grabbing;
  }
  
  .drag-handle {
    cursor: grab;
  }

/* ------------------------ */

.toggle-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    font-size: 1.4rem;
    margin-bottom: 15px;
}

.toggle-form span {
    display: flex;
    align-items: center;
}

.icon-toggle {
    margin-left: 10px;
    transition: transform 0.3s ease;
}

.toggle-form:hover .icon-toggle {
    transform: scale(1.2);
}

/* Estilo general para los ítems arrastrables */
.draggable-item {
    display: flex;
    align-items: center;
    padding: 12px;
    margin: 8px 0;
    border-radius: 8px;
    background: #ffffff;
    border: 1px solid #ddd;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: background 0.3s, box-shadow 0.3s;
    cursor: grab;
  }
  
.draggable-item:hover {
    background: #f7f7f7;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.draggable-item:active {
    background: #e0e0e0;
    cursor: grabbing;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

/* Contenedor que debe estar centrado */
.centered-container {
    position: fixed; /* Fija el contenedor en la pantalla */
    bottom: 0; /* Alinea el contenedor al fondo de la pantalla */
    left: 0;
    width: 100vw; /* Ocupa todo el ancho de la pantalla */
    height: 15vh; /* Ocupa la mitad de la altura de la pantalla */
    display: flex;
    justify-content: center; /* Centra horizontalmente el contenido */
    align-items: flex-start; /* Centra verticalmente el contenido en la parte superior del contenedor */
    padding-top: 20px; /* Añade un poco de espacio desde el borde superior si es necesario */
    box-sizing: border-box; /* Incluye el padding en el cálculo del tamaño total */
    background-color: #f4f4f4;
}

/* ANIMACIÓN PARA LAS IMAGENES */

.delete-button-galeria {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
    color: darkred;
}

.botones-galeria {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    transition: transform 0.3s ease, opacity 0.3s ease;
    background-color: #fff;
    border-top: 1px solid #ddd;
    box-shadow: 0 -2px 5px rgba(0,0,0,0.2);
    padding: 10px;
    display: flex;
    justify-content: center;
    width: 100%;
    gap: 3%;
}

.botones-galeria.closed {
    transform: translateX(-50%) translateY(calc(100% - 10px));
    opacity: 1;
    pointer-events: none;
}

.botones-galeria.open {
    transform: translateX(-50%) translateY(0);
    opacity: 1;
    pointer-events: auto;
}

.toggle-button {
    position: fixed;
    bottom: 60px; /* Ajusta según sea necesario */
    cursor: pointer;
    z-index: 1000;
    font-size: 24px;
    background: #fff;
    border-radius: 50%;
    padding: 10px;
    box-shadow: 0 0 6px rgba(0,0,0,0.1);
    pointer-events: auto;
}

.botonDeleteFotoGaleria {
    background-color: rgb(199, 0, 0);
}

.botonDeleteFotoGaleria:hover {
    background-color: rgb(156, 1, 1);
}

.btn-viajes-completados-1 {
    width: 50%; /* El botón ocupará el 50% del ancho del contenedor */
    padding: 10px;
    font-size: 14px;
    background-color: transparent; /* Fondo transparente */
    color: black; /* Texto en negro */
    border: 2px solid black; /* Borde negro */
    border-radius: 5px; /* Bordes redondeados */
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    display: inline-block;
    text-align: center;
}

.btn-viajes-completados-2 {
    width: 50%; /* El botón ocupará el 50% del ancho del contenedor */
    padding: 10px;
    font-size: 14px;
    background-color: transparent; /* Fondo transparente */
    color: black; /* Texto en negro */
    border: 2px solid black; /* Borde negro */
    border-radius: 5px; /* Bordes redondeados */
    margin: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
    display: inline-block;
    text-align: center;
}

.btn-viajes-completados-1:hover{
    background-color: black; /* Fondo negro al hacer hover */
    color: white; /* Texto en blanco al hacer hover */
}

.btn-viajes-completados-2:hover{
    background-color: black; /* Fondo negro al hacer hover */
    color: white; /* Texto en blanco al hacer hover */
}

/* SOPA DE LETRAS */
.body-sopa-de-letras {
    font-family: Arial, sans-serif;
    text-align: center;
    margin: 0;
    padding: 0;
    background-color: #f0f0f0;
}
  
.body-sopa-de-letras {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}
  
.grid {
    display: grid;
    grid-template-columns: repeat(10, 40px);
    margin-top: 20px;
}
  
.grid-cell {
    width: 40px;
    height: 40px;
    background-color: white;
    border: 2px solid #ddd;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    cursor: pointer;
    transition: background-color 0.3s ease, border-color 0.3s ease;
}
  
.grid-cell:hover {
    background-color: #f2f2f2;
}
  
.grid-cell.selected {
    background-color: #ffeb3b; /* Amarillo para resaltar la selección */
    border-color: #fbc02d; /* Amarillo más oscuro para el borde */
}

.grid-cell.found {
    background-color: #4CAF50; /* Verde */
    border-color: #388E3C; /* Borde verde oscuro */
    color: white; /* Texto blanco para mayor contraste */
    font-weight: bold; /* Negritas */
}

/* GANADOR ALEATORIO */

.eliminarJugador {
    color: red;
}

.info {
    font-size: 14px;
}

/* Estilo para el contenedor de la ruleta */
.ruleta-container {
    position: relative;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    border: 5px solid #333;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0,0,0,0.5);
}

.ruleta {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    transform: rotate(0deg);
    transition: transform 4s cubic-bezier(0.68, -0.55, 0.27, 1.55); /* Controla la duración y velocidad */
}

/* Estilo para cada jugador */
.jugador {
    position: absolute;
    width: 50%;
    height: 50%;
    left: 50%;
    top: 50%;
    transform-origin: 0% 0%;
    text-align: right;
    padding-right: 10px;
    font-size: 16px;
    font-weight: bold;
    color: white;
}

/* Colores de los jugadores */
.jugador:nth-child(odd) {
    background-color: orange;
}

.jugador:nth-child(even) {
    background-color: green;
}

.ruleta-container .indicator {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-bottom: 20px solid red;
    z-index: 2;
}

/* Página Otros */
.no-scroll {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden; /* Deshabilita el scroll completamente */
    overscroll-behavior: none; /* Evita el scroll elástico en móviles */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


/* RECETAS */
.fixed-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Rellena el espacio verticalmente, centrando la imagen */
}

.p-4 {
    padding-top: 15px !important;
}

.edit-receta {
    transition: transform 0.3s ease;
}
  
/* Activo: Reduce el tamaño como si el botón estuviera presionado */
.edit-receta:active {
    transform: scale(0.85);
}

